
import './Menu.css';

function Menu() {
  return (
    <div className="Menu">
     <div className="container">
        <img src="logo.png" className = "logo" />   
        <h1>Exbetus</h1>
     </div>
    </div>
  );
}

export default Menu;
